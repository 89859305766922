import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Paper,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Card from "./Card";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./LoginPage.css";
import useAuth from "../hooks/useAuth";
import userProfile from "../assets/user_profile.svg";
import favicon from "../assets/favicon.png";
import logo from "../assets/logo_white_transparent.png";
import bgImage from "../assets/background_image.png";

const LoginPage = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setAuth } = useAuth();

  const [enteredNameIsTouched, setEnteredNameIsTouched] = useState(false);
  const [enteredPasswordIsTouched, setEnteredPasswordIsTouched] =
    useState(false);
  const enteredNameIsValid = userName.trim() !== "";
  const enteredPasswordIsValid = password.length > 3;
  const nameInputIsValid = !enteredNameIsValid && enteredNameIsTouched;
  const passwordInputIsValid =
    !enteredPasswordIsValid && enteredPasswordIsTouched;
  const userRef = useRef();
  const navigate = useNavigate();

  const passwordRef = useRef();

  const enabled = userName.length > 0 && password.length > 0;

  const handleInputChange = (e) => {
    setUserName(e.target.value);
  };

  const hideShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const LOGIN_URL = "api/auth/v1/login";

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username: userName, password: password }),
        {
          headers: {
            "Content-Type": "application/json",
            "Time-Zone": `${timeZone}`,
          },
          withCredentials: false,
        }
      );
      const access_token = response?.data?.access_token;
      const roles = response?.data?.role;
      const refresh_token = response?.data?.refresh_token;
      const user_name = response?.data?.user_name;
      setAuth({ user_name, password, roles, access_token, refresh_token });
      setUserName("");
      setPassword("");
      if (roles === "admin") {
        navigate("/admin/home");
      } else if (roles === "member") {
        navigate("/member/home");
      } else {
        navigate("/");
      }
      return {
        access_token: window.localStorage.setItem("access_token", access_token),
        refresh_token: window.localStorage.setItem(
          "refresh_token",
          refresh_token
        ),
        user_name: window.localStorage.setItem("user_name", user_name),
      };
    } catch (err) {
      setErrorMessage(err?.response?.data?.message);
      setShowError(true);
      console.log(err);
    }
    setEnteredNameIsTouched(true);
    if (!enteredNameIsValid) {
      return;
    }

    setEnteredNameIsTouched(false);
    setEnteredPasswordIsTouched(true);
    if (!enteredPasswordIsValid) {
      return;
    }

    setEnteredPasswordIsTouched(false);
  };

  const nameInputBlurHandler = (e) => {
    setEnteredNameIsTouched(true);
  };

  const inpuFieldFocus = () => {
    setShowError(false);
  };

  const namePasswordBlurHandler = (e) => {
    setEnteredPasswordIsTouched(true);
  };

  return (
    <div className="fill-window" style={{ fontFamily: "Work Sans" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "15%",
          fontFamily: "Work Sans",
        }}
      >
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="cards">
          <Paper
            elevation={2}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              boxShadow: "0 0px 0px rgba(0,0,0,.5)",
              backdropFilter: "blur(5px)",
              webkitBackdropFilter: "blur(10px)",
            }}
          >
            {
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "24px",
                  borderRadius: "10px",
                  border: "1px solid #B2BAC2",
                  height: "533px",
                  width: "411px",
                  fontFamily: "Work Sans",
                }}
              >
                {/* <img
                  src={favicon}
                  alt=""
                  height="100px"
                  width="100px"
                  className="userProfile"
                /> */}
                <h3
                  className="h3Text"
                  style={{
                    marginBottom: "50px",
                    fontFamily: "Work Sans",
                    fontSize: "1.50rem",
                    color: "white",
                    fontWeight: 400,
                  }}
                >
                  Login to Dashboard{" "}
                </h3>
                <div className="form-text">
                  <TextField
                    id="outlined-basic"
                    error={nameInputIsValid}
                    autoComplete="off"
                    label="Username"
                    value={userName}
                    variant="outlined"
                    ref={userRef}
                    onFocus={inpuFieldFocus}
                    onBlur={nameInputBlurHandler}
                    onChange={handleInputChange}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        backgroundColor: "white",
                        color: "black",
                        width: "350px",
                        left: "50px",
                        fontFamily: "Work Sans",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "black",
                        left: "50px",
                        fontFamily: "Work Sans",
                      },
                    }}
                  />
                  {nameInputIsValid && (
                    <p
                      className="error-text"
                      style={{ fontSize: "13px", marginLeft: "45px" }}
                    >
                      Name must not be empty.
                    </p>
                  )}
                </div>
                <div className="form-text">
                  <FormControl variant="outlined">
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      style={{
                        color: "black",
                        left: "50px",
                        fontFamily: "Work Sans",
                      }}
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={inpuFieldFocus}
                      onBlur={namePasswordBlurHandler}
                      // minLength="4"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={hideShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      ref={passwordRef}
                      error={passwordInputIsValid}
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        width: "350px",
                        left: "50px",
                        fontFamily: "Work Sans",
                      }}
                    />
                  </FormControl>
                  <div
                    className="panel"
                    style={{
                      width: "100%",
                      height: "40px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontFamily: "Work Sans",
                    }}
                  >
                    <p>Remember Me</p>
                    <p>Forgot Password?</p>
                  </div>
                  {passwordInputIsValid && (
                    <p
                      className="error-text"
                      style={{ fontSize: "13px", marginLeft: "45px" }}
                    >
                      Password length should be more than 4.
                    </p>
                  )}
                </div>
                {showError && (
                  <p style={{ marginLeft: "10px" }} className="error-text">
                    {errorMessage}
                  </p>
                )}
                <Button
                  type="submit"
                  disabled={!enabled}
                  variant="contained"
                  style={{
                    width: "35%",
                    marginTop: "20px",
                    padding: "10px",
                    marginBottom: "30px",
                    background: "#239bb9",
                    color: "#fff",
                    borderRadius: "15px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Work Sans",
                  }}
                >
                  Sign In
                </Button>
              </form>
            }
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import React, { useState, useEffect } from "react";
import "./AdminPage.css";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";
import user_profile from "../../assets/user_profile.svg";
import logo_white from "../../assets/logo_white_transparent.png";
import HomeIcon from "@mui/icons-material/Home";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import EventIcon from "@mui/icons-material/Event";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function AdminPage(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openAccess, setOpenAccess] = useState(true);
  const [openControl, setOpenControl] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [drawerWidth, setDrawerWidth] = useState(64);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (mobileOpen) {
      setDrawerWidth(216);
    } else {
      setDrawerWidth(64);
    }
  }, [mobileOpen]);

  let params = useParams();
  let url = Object.values(params)[0];

  useEffect(() => {
    if (url.includes("home")) {
      setSelectedIndex(0);
    }

    if (url.includes("events")) {
      setSelectedIndex(1);
    }
    if (url.includes("profile")) {
      setSelectedIndex(2);
    }
  }, [url]);

  const logout = useLogout();

  const logoutHandler = async () => {
    setIsLoading(true);
    await logout();
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (mobileOpen && !event.target.closest(".MuiDrawer-root")) {
        setMobileOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [mobileOpen]);

  const handleAccessClick = () => {
    if (openControl && openAccess) {
      setOpenAccess(!openAccess);
    } else if (!openControl && openAccess) {
      setOpenAccess(!openAccess);
      setOpenControl(true);
    } else setOpenAccess(true);
  };

  const handleControlClick = () => {
    if (openControl && openAccess) {
      setOpenControl(!openControl);
    } else if (openControl && !openAccess) {
      setOpenControl(!openControl);
      setOpenAccess(true);
    } else setOpenControl(true);
  };

  const drawer = (
    <div>
      <Toolbar
        sx={{
          height: "64px",
          paddingTop: "16px",
          paddingLeft: "12px",
          paddingBottom: "10px",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{
            color: { xs: !mobileOpen ? "black" : "white", sm: "white" },
            width: "40px",
            height: `${drawerWidth}`,
          }}
          style={{ paddingRight: "8px" }}
        >
          <MenuIcon />
        </IconButton>
        <div
          style={{
            display: mobileOpen ? "" : "none",
            marginLeft: "8px",
            marginBottom: "10px",
            color: "white",
          }}
        >
          <img src={logo_white} alt="" style={{ width: "100%" }} />
        </div>
      </Toolbar>
      <div
        style={{
          display: mobileOpen ? "flex" : "none",
          alignItems: "center",
          margin: "0 0 10px 10px",
        }}
      >
        <img
          className="profile"
          src={user_profile}
          alt=""
          width="60px"
          height="60px"
          style={{ marginTop: "14px", marginRight: "14px", padding: "0px" }}
        />
        <label style={{ marginTop: "10px" }}>
          <h3 style={{ marginBottom: "0px" }}>
            <strong style={{ textTransform: "capitalize", color: "#fff" }}>
              {localStorage.getItem("user_name")}
            </strong>
          </h3>
          <p style={{ marginTop: "0px", color: "#fff" }}>{"Admin"}</p>
        </label>
      </div>
      <Divider
        style={{
          marginLeft: "12px",
          marginRight: "12px",
          marginBottom: "20px",
          background: "#FFF",
          opacity: "0.20000000298023224",
        }}
        sx={{
          display: { xs: !mobileOpen ? "none" : "block", sm: "block" },
        }}
      />
      <List
        style={{ paddingTop: 0, paddingBottom: 0 }}
        sx={{
          display: { xs: !mobileOpen ? "none" : "block", sm: "block" },
        }}
      >
        <Tooltip
          arrow
          title="Home"
          placement="right"
          disableHoverListener={mobileOpen}
          componentsProps={{
            tooltip: {
              sx: {
                color: "black",
                background: "#FFF",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Work Sans",
                fontSize: "12px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                "& .MuiTooltip-arrow": {
                  color: "#FFF !important",
                },
              },
            },
          }}
        >
          <ListItemButton
            style={{
              background: selectedIndex === 0 ? "#49758d" : "",
              margin: "10px",
              padding: "12px",
              height: "40px",
              borderRadius: "3px",
            }}
            onClick={() => {
              navigate("home");
              setSelectedIndex(0);
              setMobileOpen(false);
            }}
          >
            <ListItemIcon
              style={{
                color: "white",
                minWidth: "0px",
              }}
            >
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              style={{
                display: mobileOpen ? "" : "none",
                marginLeft: "28px",
                marginTop: "8px",
                color: "white",
              }}
              primary={
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "Work Sans",
                  }}
                >
                  Home
                </Typography>
              }
            />
          </ListItemButton>
        </Tooltip>
        <Tooltip
          arrow
          title="Events"
          placement="right"
          disableHoverListener={mobileOpen}
          componentsProps={{
            tooltip: {
              sx: {
                color: "black",
                background: "#FFF",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Work Sans",
                fontSize: "12px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                "& .MuiTooltip-arrow": {
                  color: "#FFF !important",
                },
              },
            },
          }}
        >
          <ListItemButton
            style={{
              background: selectedIndex === 1 ? "#49758d" : "",
              margin: "10px",
              padding: "12px",
              height: "40px",
              borderRadius: "3px",
            }}
            onClick={() => {
              navigate("events");
              setSelectedIndex(1);
              setMobileOpen(false);
            }}
          >
            <ListItemIcon
              style={{
                color: "white",
                minWidth: "0px",
              }}
            >
              <EventIcon />
            </ListItemIcon>
            <ListItemText
              style={{
                display: mobileOpen ? "" : "none",
                marginLeft: "28px",
                marginTop: "8px",
                color: "white",
              }}
              primary={
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "Work Sans",
                  }}
                >
                  Events
                </Typography>
              }
            />
          </ListItemButton>
        </Tooltip>
      </List>
      <Divider
        sx={{
          display: { xs: !mobileOpen ? "none" : "block", sm: "block" },
        }}
      />
      <List
        sx={{
          display: {
            xs: !mobileOpen ? "none" : "block",
            sm: "block",
          },
          position: { xs: !mobileOpen ? "none" : "absolute", sm: "absolute" },
        }}
        style={{
          display: "flex",
          bottom: "0",
          width: "100%",
        }}
      >
        <Tooltip
          arrow
          title="Logout"
          placement="right"
          disableHoverListener={mobileOpen}
          componentsProps={{
            tooltip: {
              sx: {
                color: "black",
                background: "#FFF",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Work Sans",
                fontSize: "12px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                "& .MuiTooltip-arrow": {
                  color: "#FFF !important",
                },
              },
            },
          }}
        >
          <ListItemButton
            style={{
              background: selectedIndex === 3 ? "#49758d" : "",
              margin: "10px",
              padding: "12px",
              height: "40px",
              borderRadius: "3px",
            }}
            onClick={logoutHandler}
          >
            <ListItemIcon
              style={{
                color: "white",
                minWidth: "0px",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    fontFamily: "Work Sans",
                  }}
                >
                  Logout
                </Typography>
              }
              style={{
                display: mobileOpen ? "" : "none",
                marginLeft: "28px",
                marginTop: "8px",
                color: "white",
              }}
            />
          </ListItemButton>
        </Tooltip>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex", overflow: "auto" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          background: "rgb(234, 237, 235)",
        }}
        aria-label="mailbox folders"
      >
        {/* <Drawer
          // style={{ paddingLeft: "8px" }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer> */}
        <Drawer
          variant="permanent"
          style={{ transition: "0.2s", borderRadius: "10px" }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: {
                xs: !mobileOpen ? "50px" : `${drawerWidth}px`,
                sm: `${drawerWidth}px`,
              },
              height: { xs: !mobileOpen ? "64px" : "auto", sm: "auto" },
              // transition: "0.5s",
              // overflow: "hidden",
              background: {
                xs: !mobileOpen ? "#fff" : "#294A50",
                sm: "#294A50",
              },
              borderRight: "none",
              borderRadius: "10px",
              top: "5px",
              left: "5px",
              bottom: "5px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        className="outlet-box"
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
        }}
        style={{ marginTop: "0px", backgroundColor: "#eaedeb" }}
      >
        {isLoading ? (
          <CircularProgress style={{ marginLeft: "50%" }} />
        ) : (
          <Outlet context={{ handleDrawerToggle, mobileOpen }} />
        )}
      </Box>
    </Box>
  );
}

AdminPage.propTypes = {
  window: PropTypes.func,
};

export default AdminPage;

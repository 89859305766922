// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
  max-height: 300px;
  overflow-y: auto;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th {
  position: sticky;
  top: 0;
  background-color: #f9f9f9; /* Set a background color for the fixed header */
  text-align: left;
}

.table-container thead tbody {
  display: block;
}

.table-container tbody {
  position: relative; /* Ensure relative positioning for the table body */
  max-height: 250px;
  overflow-y: auto;
}

.table-container tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-container td,
th {
  padding: 10px; /* Adjust padding as needed */
}

.scrollable-content {
  /* max-height: 270px;
  overflow-y: auto; */
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profile/Profile.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,yBAAyB,EAAE,gDAAgD;EAC3E,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB,EAAE,mDAAmD;EACvE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,mBAAmB;AACrB;;AAEA;;EAEE,aAAa,EAAE,6BAA6B;AAC9C;;AAEA;EACE;qBACmB;EACnB,cAAc;AAChB","sourcesContent":[".table-container {\n  max-height: 300px;\n  overflow-y: auto;\n}\n\n.table-container table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table-container th {\n  position: sticky;\n  top: 0;\n  background-color: #f9f9f9; /* Set a background color for the fixed header */\n  text-align: left;\n}\n\n.table-container thead tbody {\n  display: block;\n}\n\n.table-container tbody {\n  position: relative; /* Ensure relative positioning for the table body */\n  max-height: 250px;\n  overflow-y: auto;\n}\n\n.table-container tr {\n  display: table;\n  width: 100%;\n  table-layout: fixed;\n}\n\n.table-container td,\nth {\n  padding: 10px; /* Adjust padding as needed */\n}\n\n.scrollable-content {\n  /* max-height: 270px;\n  overflow-y: auto; */\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

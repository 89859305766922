import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import Layout from "./components/Layout";
import AdminPage from "./components/Admin/AdminPage";
import MemberPage from "./components/Events/MemberPage";
import HomePage from "./components/HomePage";
import PersistLogin from "./components/PersistLogin";
import Settings from "./components/Settings";
import RequireAuth from "./components/RequireAuth";
import MemberEvents from "./components/Events/MemberEvents";
import ViewEventsTable from "./components/common/Tables/ViewEventsTable";
import DevicesTable from "./components/common/Devices/DevicesTable";
import RegisterDevices from "./components/common/Devices/RegisterDevices";
import Profile from "./components/Profile/Profile";
import "./App.css";
import ViewEventsModal from "./components/Events/ViewEventsModal";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<LoginPage />} />
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="admin" element={<AdminPage />}>
              <Route path="home" element={<HomePage />} />
              <Route path="events" element={<ViewEventsModal />} />
              <Route path="profile" element={<Profile />} />
              <Route path="devices" element={<DevicesTable />} />
              <Route
                path="devices/register-device"
                element={<RegisterDevices />}
              />
              <Route path="settings" element={<Settings />} />
            </Route>

            <Route path="member" element={<MemberPage />}>
              <Route path="home" element={<HomePage />} />
              <Route path="events" element={<MemberEvents />} />
              <Route path="settings" element={<Settings />} />
              <Route path="profile" element={<Profile />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

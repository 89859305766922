import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AppBarCommon from "../AppBarCommon";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorIcon from "@mui/icons-material/Error";
import useIntersectionObserver from "../../hooks/useIntersectioObserver";
import MenuIcon from "@mui/icons-material/Menu";
import TableChartIcon from "@mui/icons-material/TableChart";
import EventsModal from "./EventsModal";
import RealEventsModal from "./RealEventsModal";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventsTableView from "./EventsTableView";
import ViewEventsTable from "../common/Tables/ViewEventsTable";

const ImageWithPlaceholder = ({ src, placeholder = null, style }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = (event) => {
    setImageLoaded(true);
  };

  return (
    <img
      src={src}
      alt=""
      style={imageLoaded ? style : { visibility: "hidden" }}
      onLoad={handleImageLoaded}
    />
  );
};

const ViewEventsModal = (props) => {
  const { isHomePage, hasAdminRole } = props;
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isListView, setISListView] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [openRealEventsModal, setRealEventsOpenModal] = useState(false);
  const [openRealModal, setRealOpenModal] = useState(false);
  const [modalMinimize, setModalMinimize] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDefective, setIsDefective] = useState(false);

  const fetchData = async () => {
    if (loading || page > totalPages) return;
    setLoading(true);
    try {
      const response = await axiosPrivate.get(
        `/defect-inspection/v1/events?page=${page}&limit=10`
      );
      setData((prevData) => [...prevData, ...response?.data?.events]);
      setTotalPages(response?.data?.page_metadata?.total_pages);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    setISListView(true);
    setIsGridView(false);
  }, []);

  useEffect(() => {
    setIsDefective(true);
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const handleIntersection = (entry) => {
    if (entry.isIntersecting && !loading && page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const lastItemRef = useIntersectionObserver(handleIntersection, [
    loading,
    totalPages,
  ]);

  const filteredEvents = data?.filter((event) => {
    const isSearchMatch = searchTerm
      ? event.workpiece_id.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    const isDateMatch = selectedDate
      ? new Date(event.created_date).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString()
      : true;
    return isSearchMatch && isDateMatch;
  });

  const eventsToShow = searchTerm || selectedDate ? filteredEvents : data;

  const handleListView = () => {
    setISListView(true);
    setIsGridView(false);
  };

  const handleGridView = () => {
    setIsGridView(true);
    setISListView(false);
  };

  const handleCloseRealEventsModal = () => {
    setRealEventsOpenModal(false);
  };

  const handleCloseRealModal = () => {
    setRealOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleMinimizeBtn = (val) => {
    setModalMinimize(val);
  };

  const viewRealTime = () => {
    setRealOpenModal(true);
  };
  const viewRealTimeEvents = () => {
    setRealEventsOpenModal(true);
  };

  return (
    <>
      {!isHomePage && (
        <>
          <AppBarCommon
            name="Events"
            viewEvents={true}
            viewBtn={true}
            miniMize={modalMinimize}
            viewRealTime={viewRealTime}
            viewRealTimeEvents={viewRealTimeEvents}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            style={{ position: "", top: 0, width: "100%", zIndex: 10 }}
          />
          <EventsModal
            openModal={openRealEventsModal}
            handleClose={handleCloseRealEventsModal}
          ></EventsModal>
          <RealEventsModal
            openModal={openRealModal}
            handleClose={handleCloseRealModal}
            handleMinimize={handleMinimizeBtn}
          ></RealEventsModal>
        </>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "fit-content",
          position: "relative",
          left: "91%",
        }}
      >
        <IconButton onClick={handleListView}>
          <MenuIcon style={{ color: isListView ? "#239BB9" : "#9CE0E0" }} />
        </IconButton>
        <IconButton onClick={handleGridView}>
          <TableChartIcon
            style={{ color: isGridView ? "#239BB9" : "#9CE0E0" }}
          />
        </IconButton>
      </div>

      {isListView ? (
        <>
          <div
            style={{
              height: "calc(100vh - 80px)",
              overflowY: "auto",
              display: "flex",
              flexDirection: isListView ? "column" : "row",
              alignItems: "center",
              justifyContent: isListView ? "center" : "flex-start",
            }}
          >
            {eventsToShow && eventsToShow.length > 0 ? (
              <div
                style={{
                  display: isListView ? "block" : "grid",
                  gridTemplateColumns: isListView ? "none" : "repeat(2, 1fr)",
                  gap: "20px",
                  width: isListView ? "100%" : "100%",
                  // maxWidth: isListView ? "900px" : "unset",
                  padding: isListView ? "0" : "20px",
                  height: "calc(100vh - 80px)",
                }}
              >
                {eventsToShow?.map((event, index) => {
                  const tableData =
                    event?.additional_info?.detections?.measurements;
                  const error_threshold = event?.additional_info?.threshold;
                  const isLastItem = eventsToShow.length === index + 1;
                  return (
                    <div
                      key={index}
                      ref={isLastItem ? lastItemRef : null}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        margin: "auto",
                        width: "95%",
                        boxSizing: "border-box",
                      }}
                    >
                      <img
                        src={event?.image}
                        alt=""
                        width="100%"
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          height: "70vh",
                          objectFit: "cover",
                        }}
                      />

                      {event?.status == "defective" && (
                        <div
                          style={{
                            position: "absolute",
                            top: "16px",
                            zIndex: 10,
                            left: "35px",
                            color: "#E25984",
                            backgroundColor: "#FEE0E5",
                            display: "flex",
                            alignItems: "center",
                            padding: "6px",
                            gap: "10px",
                            borderRadius: "20px",
                            width: "140px",
                          }}
                        >
                          <ErrorIcon style={{ color: "#E25984" }} />
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "Work Sans",
                              textTransform: "capitalize",
                            }}
                          >
                            {event?.status}
                          </p>
                        </div>
                      )}
                      {event?.status == "good workpiece" && (
                        <div
                          style={{
                            position: "absolute",
                            top: "16px",
                            zIndex: 10,
                            left: "35px",
                            color: "#13A13E",
                            backgroundColor: "#EEFBF2",
                            display: "flex",
                            alignItems: "center",
                            padding: "6px",
                            gap: "10px",
                            borderRadius: "20px",
                            width: "140px",
                          }}
                        >
                          <ErrorIcon style={{ color: "#13A13E" }} />
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              fontFamily: "Work Sans",
                              textTransform: "capitalize",
                            }}
                          >
                            No Defect
                          </p>
                        </div>
                      )}
                      <div
                        style={{
                          width: "100%",
                          marginBottom: "10px",
                        }}
                      >
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                          >
                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: 500,
                                  color: "#115061",
                                  fontSize: "24px",
                                }}
                              >{`#${event?.workpiece_id}`}</p>
                              <p style={{ fontWeight: 500 }}>view details</p>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {tableData && (
                                <div
                                  className="table-container"
                                  style={{ marginTop: "14px" }}
                                >
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Title</th>
                                        <th>Orientation</th>
                                        <th>Length</th>
                                        <th>Measured length</th>
                                        <th>Error (+/-)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tableData.map((rowData, index) => (
                                        <tr key={index}>
                                          <td>{rowData.title}</td>
                                          <td>{rowData.orientation}</td>
                                          <td>{rowData.length}</td>
                                          <td>
                                            {rowData.predicted_length.toFixed(
                                              2
                                            )}
                                          </td>
                                          <td>
                                            {error_threshold ? (
                                              error_threshold >
                                              Math.abs(
                                                rowData.length -
                                                  rowData.predicted_length
                                              ).toFixed(2) ? (
                                                <span
                                                  style={{
                                                    display: "inline",
                                                    backgroundColor:
                                                      "rgb(224, 255, 234)",
                                                    color: "#008000",
                                                    borderRadius: "10px",
                                                    padding: "5px 10px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {(
                                                    rowData.length -
                                                    rowData.predicted_length.toFixed(
                                                      2
                                                    )
                                                  ).toFixed(2)}
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "inline",
                                                    color: "#F44336",
                                                    backgroundColor: "#ffb1ac",
                                                    borderRadius: "10px",
                                                    padding: "5px 10px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {(
                                                    rowData.length -
                                                    rowData.predicted_length.toFixed(
                                                      2
                                                    )
                                                  ).toFixed(2)}
                                                </span>
                                              )
                                            ) : (
                                              <span
                                                style={{
                                                  display: "inline",
                                                  backgroundColor: "#e9e9e9",
                                                  borderRadius: "10px",
                                                  padding: "5px 10px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {(
                                                  rowData.length -
                                                  rowData.predicted_length
                                                ).toFixed(2)}
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Typography
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontWeight: "bold",
                  color: "#115061",
                  fontSize: "20px",
                  fontFamily: "Work Sans",
                }}
              >
                No Data Available
              </Typography>
            )}
            {loading && (
              <p style={{ fontFamily: "Work Sans", fontSize: "18px" }}>
                Loading....
              </p>
            )}
          </div>
          <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle
              style={{ fontFamily: "Work Sans", position: "relative" }}
            >
              Order Number: 1683124502
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                width: "fit-content",
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <img
                src="https://brakepress.yottaasys.com/brakepress-api-service/defect-inspection/v1/images-lowres/defect/1727365835/2024-09-26-15-51-1191778.png"
                alt=""
                height="330px"
                width="100%"
              />
              <div
                style={{
                  position: "absolute",
                  top: "100px",
                  zIndex: 10,
                  left: "35px",
                  color: isDefective ? "#E25984" : "#13A13E",
                  backgroundColor: isDefective ? "#FEE0E5" : "#EEFBF2",
                  display: "flex",
                  alignItems: "center",
                  padding: "6px",
                  gap: "10px",
                  borderRadius: "20px",
                  width: "140px",
                }}
              >
                {isDefective ? (
                  <ErrorIcon style={{ color: "#E25984" }} />
                ) : (
                  <CheckCircleIcon style={{ color: "#13A13E" }} />
                )}
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    fontFamily: "Work Sans",
                    textTransform: "capitalize",
                  }}
                >
                  {isDefective ? "Defective" : "No Defect"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: !isDefective ? "24px" : "28px",
                    fontFamily: "Work Sans",
                    width: "50%",
                    padding: "15px",
                    fontWeight: !isDefective ? 500 : 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    backgroundColor: !isDefective && "#E6EDED",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsDefective(true)}
                >
                  Side 1
                  <ErrorIcon style={{ color: "#E25984" }} />
                </div>
                <div
                  style={{
                    fontSize: isDefective ? "24px" : "28px",
                    fontFamily: "Work Sans",
                    width: "50%",
                    padding: "15px",
                    fontWeight: isDefective ? 500 : 700,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    backgroundColor: isDefective && "#E6EDED",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsDefective(false)}
                >
                  Side 2
                  <CheckCircleIcon style={{ color: "#13A13E" }} />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <EventsTableView
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
    </>
  );
};

export default ViewEventsModal;

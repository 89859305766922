import {
  Box,
  Chip,
  ClickAwayListener,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./EventsTableView.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ViewFramesModal from "../common/Tables/ViewFramesModal";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import RemoveIcon from "@mui/icons-material/Remove";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const PaginationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end", // Spacing between elements
  padding: "16px",
  backgroundColor: "transparent",
  width: "100%",
  fontFamily: "Work Sans !important",
  flex: 1,
}));

const CustomTablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const start = page * rowsPerPage + 1;
  const end = Math.min(count, (page + 1) * rowsPerPage);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        marginRight: "300px",
      }}
    >
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        style={{ width: "50px" }}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        style={{ width: "50px" }}
      >
        <KeyboardArrowLeft />
      </IconButton>

      <span
        style={{
          margin: "0 8px",
          whiteSpace: "nowrap",
          fontFamily: "Work Sans",
          fontWeight: "bold",
        }}
      >
        {`${start}-${end} of ${count}`}
      </span>

      <IconButton
        onClick={handleNextButtonClick}
        style={{ width: "50px" }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        style={{ width: "50px" }}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPage />
      </IconButton>
    </div>
  );
};

const EventsTableView = ({
  searchTerm,
  setSearchTerm,
  selectedDate,
  setSelectedDate,
}) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const axiosPrivate = useAxiosPrivate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [eventData, setEventData] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [bottomDrop, setBottomDrop] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagActive, setTagActive] = useState(false);
  const [tagID, setTagID] = useState("");
  const [isTagged, setIsTagged] = useState(false);
  const [customTags, setCustomTags] = useState([]);
  const [otherPrediction, setOtherPrediction] = useState([]);
  const [customPrediction, setCustomPrediction] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const filteredEvents = data?.filter((event) => {
    const isSearchMatch = searchTerm
      ? event.workpiece_id.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    const isDateMatch = selectedDate
      ? new Date(event.created_date).toLocaleDateString() ===
        new Date(selectedDate).toLocaleDateString()
      : true;
    return isSearchMatch && isDateMatch;
  });

  const eventsToShow = searchTerm || selectedDate ? filteredEvents : data;

  const handleSortByDate = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };

  const sortedEvents = [...eventsToShow].sort((a, b) => {
    const dateA = new Date(a.created_date);
    const dateB = new Date(b.created_date);

    if (sortOrder === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });

  const fetchData = async (page, rowsPerPage) => {
    try {
      const response = await axiosPrivate.get(`/defect-inspection/v1/events`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      });
      setData(response.data.events);
      setCount(response.data.page_metadata.total_count);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheck = (data) => {
    const wronginTagsIndex = customTags.findIndex(
      (item) => item === "wrongprediction"
    );
    if (wronginTagsIndex == -1) {
      customTags.unshift("wrongprediction");
      setIsTagged(true);
    } else {
      let customTagsTemp = customTags.filter((item) => {
        return item !== "wrongprediction";
      });
      setCustomTags(customTagsTemp);
      setIsTagged(false);
    }
  };

  const handleChipDelete = (item, index) => {
    setCustomTags((customTags) =>
      customTags.filter((zitem, zindex) => zindex != index)
    );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (customPrediction.length) {
      customTags.push(customPrediction);
      setCustomPrediction("");
    }
  };

  const tagSubmit = async (data) => {
    const tagsArr = customTags;
    const access_token = localStorage.getItem("access_token");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let body = {
      tags: tagsArr,
    };

    const response = await axiosPrivate.post(
      `/defect-inspection/v1/events/${data.event_id}/add_tags`,
      body,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Time-Zone": `${timeZone}`,
        },
        withCredentials: false,
      }
    );
    setTagActive(false);
    setTags([]);
    setCustomTags([]);
  };

  const handleRowClick = (index) => {
    setOpenRowIndex(openRowIndex === index ? null : index); // Toggle the row
  };

  const makeDateReadable = (stringValue) => {
    const newDate = new Date(stringValue + " UTC");
    const finalResult = newDate.toLocaleString();
    return finalResult;
  };

  const handleOpenModal = (data) => {
    setOpenModal(true);
    setSelectedImage(data);
    setEventData(data);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTagin = (e, data) => {
    console.log("tag", data);

    if (e.screenY > 465) {
      setBottomDrop(true);
    } else {
      setBottomDrop(false);
    }
    setTagActive(true);
    setTags(data.tags);
    setCustomTags(data.tags);
    setTagID(data.event_id);
    const otherPredictioninTag = data.tags?.filter(
      (item) => item != "wrongprediction"
    );
    setOtherPrediction(otherPredictioninTag);
    if (data.tags.length) {
      data.tags.map((item) => {
        if (item == "wrongprediction") setIsTagged(true);
      });
    } else {
      setIsTagged(false);
    }
  };

  const handleCloseActiveTag = () => {
    setTagActive(false);
    setTags([]);
    setCustomTags([]);
    setCustomPrediction("");
    setOtherPrediction([]);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{ margin: "20px", width: "97%", height: "calc(100vh - 80px)" }}
      >
        <Table
          sx={{
            borderSpacing: "0 16px", // Adds vertical spacing between table head and body
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Event ID</TableCell>
              <TableCell>Work Piece Id</TableCell>
              <TableCell>Order Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Captured Image</TableCell>
              <TableCell>
                Created Date{" "}
                <IconButton
                  style={{ height: "50px", width: "50px" }}
                  onClick={handleSortByDate}
                >
                  <SwapVertIcon style={{ color: "#155d6f" }} />
                </IconButton>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          {sortedEvents && sortedEvents.length > 0 ? (
            sortedEvents.map((data, index) => {
              const tableData = data?.additional_info?.detections?.measurements;
              const error_threshold = data?.additional_info?.threshold;
              return (
                <TableBody key={index} style={{ fontFamily: "Work Sans" }}>
                  <TableRow>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleRowClick(index)}
                      >
                        {openRowIndex === index ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{data?.event_id}</TableCell>
                    <TableCell>{data?.workpiece_id || " "}</TableCell>
                    <TableCell>{data?.order_number || " "}</TableCell>
                    <TableCell>{data?.status || " "}</TableCell>
                    <TableCell>
                      <div
                        variant="contained"
                        type="button"
                        style={{
                          // background: "rgb(55 48 224)",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          // cursor: "pointer",
                        }}
                        //   onClick={() => {
                        //     handleOpenModal(data);
                        //   }}
                      >
                        <div className="image-container">
                          <img
                            alt=""
                            onLoad={() => setShowImage(true)}
                            key={data?.index}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                            src={data?.image}
                          ></img>
                          {!showImage && (
                            <div className="image-placeholder"></div>
                          )}
                        </div>
                      </div>
                      {openModal && (
                        <ViewFramesModal
                          selectedImage={selectedImage}
                          openModal={openModal}
                          handleClose={handleCloseModal}
                          eventData={eventData}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {makeDateReadable(data?.created_date) || " "}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{ position: "relative" }}
                        key={openRowIndex === index}
                      >
                        <LocalOfferIcon
                          onClick={(e) => {
                            handleTagin(e, data);
                          }}
                          style={{
                            cursor: "pointer",
                            color:
                              tagActive && data.event_id === tagID
                                ? "#656363"
                                : data.tags.length
                                ? "#70ca70"
                                : "#6462627a",
                          }}
                        ></LocalOfferIcon>
                        {tagActive && data.event_id === tagID && (
                          <ClickAwayListener
                            onClickAway={() => handleCloseActiveTag()}
                          >
                            <div
                              style={{
                                position: "absolute",
                                width: "200px",
                                minHeight: "120px",
                                zIndex: 1,
                                borderRadius: "6px",
                                right: "40px",
                                background: "white",
                                bottom: bottomDrop ? "40px" : "",
                                padding: "12px",
                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                              }}
                            >
                              <Chip
                                icon={RemoveIcon}
                                style={{ margin: "4px" }}
                                label="Wrong Prediction"
                                color={isTagged ? "primary" : "default"}
                                onClick={() => handleCheck(data)}
                              />
                              {/* {otherPrediction.length != 0 && (
                    <>
                      {otherPrediction.map((item, index) => {
                        return (
                          <Chip
                            onDelete={() => alert("hi")}
                            style={{ margin: "4px" }}
                            key={index}
                            label={item}
                            color="primary"
                          ></Chip>
                        );
                      })}
                    </>
                  )} */}
                              {customTags.length != 0 && (
                                <>
                                  {customTags.map((item, index) => {
                                    if (item !== "wrongprediction")
                                      return (
                                        <Chip
                                          onDelete={() =>
                                            handleChipDelete(item, index)
                                          }
                                          style={{ margin: "4px" }}
                                          key={index}
                                          label={item}
                                          color="primary"
                                        ></Chip>
                                      );

                                    return null;
                                  })}
                                </>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "6px",
                                }}
                              >
                                <form
                                  onSubmit={(e) => handleFormSubmit(e)}
                                  style={{ width: "90%" }}
                                >
                                  <input
                                    placeholder="Add Custom Tag"
                                    style={{
                                      height: "34px",
                                      margin: "4px",
                                      width: "96%",
                                    }}
                                    onChange={(e) =>
                                      setCustomPrediction(e.target.value)
                                    }
                                    value={customPrediction}
                                  ></input>
                                </form>
                                <FileUploadIcon
                                  onClick={() => tagSubmit(data)}
                                  style={{
                                    height: "32px",
                                    width: "32px",
                                    marginLeft: "4px",
                                    color: "#70ca70",
                                    cursor: "pointer",
                                  }}
                                ></FileUploadIcon>
                              </div>
                            </div>
                          </ClickAwayListener>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={7}
                      id={index}
                    >
                      <Collapse
                        in={openRowIndex === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          {tableData && (
                            <>
                              <Typography>Details</Typography>
                              <div
                                className="table-container"
                                style={{ marginTop: "14px" }}
                              >
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Title</th>
                                      <th>Orientation</th>
                                      <th>Length</th>
                                      <th>Measured length</th>
                                      <th>Error (+/-)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tableData.map((rowData, index) => (
                                      <tr key={index}>
                                        <td>{rowData.title}</td>
                                        <td>{rowData.orientation}</td>
                                        <td>{rowData.length}</td>
                                        <td>
                                          {rowData.predicted_length.toFixed(2)}
                                        </td>
                                        <td>
                                          {error_threshold ? (
                                            error_threshold >
                                            Math.abs(
                                              rowData.length -
                                                rowData.predicted_length
                                            ).toFixed(2) ? (
                                              <span
                                                style={{
                                                  display: "inline",
                                                  backgroundColor:
                                                    "rgb(224, 255, 234)",
                                                  color: "#008000",
                                                  borderRadius: "10px",
                                                  padding: "5px 10px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {(
                                                  rowData.length -
                                                  rowData.predicted_length.toFixed(
                                                    2
                                                  )
                                                ).toFixed(2)}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  display: "inline",
                                                  color: "#F44336",
                                                  backgroundColor: "#ffb1ac",
                                                  borderRadius: "10px",
                                                  padding: "5px 10px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {(
                                                  rowData.length -
                                                  rowData.predicted_length.toFixed(
                                                    2
                                                  )
                                                ).toFixed(2)}
                                              </span>
                                            )
                                          ) : (
                                            <span
                                              style={{
                                                display: "inline",
                                                backgroundColor: "#e9e9e9",
                                                borderRadius: "10px",
                                                padding: "5px 10px",
                                                textAlign: "center",
                                              }}
                                            >
                                              {(
                                                rowData.length -
                                                rowData.predicted_length
                                              ).toFixed(2)}
                                            </span>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          ) : (
            <Typography
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "bold",
                color: "#115061",
                fontSize: "20px",
                fontFamily: "Work Sans",
              }}
            >
              No Data Available
            </Typography>
          )}
        </Table>
      </TableContainer>
      <PaginationContainer>
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Rows per page:"
          ActionsComponent={CustomTablePaginationActions}
          labelDisplayedRows={() => null}
          sx={{
            overflow: "visible",
            fontFamily: "Work Sans",
            display: "flex",
            justifyContent: "space-between", // Ensure content is spaced
            alignItems: "center",
            "& .MuiTablePagination-toolbar": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            },
            "& .MuiTablePagination-actions": {
              order: -1,
              margin: "0 auto", // Center the pagination buttons
            },
            "& .MuiTablePagination-selectLabel, & .MuiInputBase-root": {
              order: 1,
              marginLeft: "10px",
              fontFamily: "Work Sans",
            },
            "& .MuiTablePagination-selectLabel": {
              marginRight: "8px",
            },
            "& .MuiInputBase-root": {
              display: "flex",
              alignItems: "center",
              fontWeight: 700,
            },
          }}
          SelectProps={{
            renderValue: (value) => `${value} rows`,
            style: { fontFamily: "Work Sans" },
          }}
        />
      </PaginationContainer>

      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        classes={{
          root: "pagination-root",
          actions: "pagination-actions",
        }}
      /> */}
    </>
  );
};

export default EventsTableView;

import React, { forwardRef, useEffect, useState } from "react";
import MaterialTable from '@material-table/core';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, TablePagination } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CheckIcon from "@mui/icons-material/Check";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SearchIcon from "@mui/icons-material/Search";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { ThemeProvider, createTheme } from "@mui/material";
import AppBarCommon from "../../AppBarCommon";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BASE_URL } from "../../../api/axios";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutlineIcon {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRightIcon {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterAltIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => (
    <ChevronRightIcon {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeftIcon {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownwardIcon {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <RemoveIcon {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumnIcon {...props} ref={ref} />
  )),
};

const DeviceTable = (props) => {
  const { isHomePage, hasAdminRole } = props;
  const axiosPrivate = useAxiosPrivate();
  const defaultMaterialTheme = createTheme();
  const [filter, setFilter] = useState(false);

  let url = BASE_URL;

  const handleChange = () => {
    setFilter(!filter);
  };

  const controller = new AbortController();
  const getDevices = async (url) => {
    try {
      const userResponse = await axiosPrivate.get(url, {
        signal: controller.signal,
      });
      return userResponse.data;
    } catch (err) {
      console.log(err);
    }
  };

  const makeDateReadable = (stringValue) => {
    const res = stringValue.split(" ");
    const date = res[0];
    const mixTime = res[1];

    const splitTime = mixTime.split(":");

    const hh = splitTime[0];
    const mm = splitTime[1];

    const finalResult = date + " " + hh + ":" + mm;

    return finalResult;
  };

  const columns = [
    { title: "Device Id", field: "device_id" },
    {
      title: "Edge cpu",
      field: "edge_cpu.sku_id",
    },
    {
      title: "Camera 1",
      field: "camera1.sku_id",
    },
    {
      title: "Camera 2",
      field: "camera2.sku_id",
    },

    {
      title: "Created Date",
      field: "created_date",
      render: (rowData) => makeDateReadable(rowData.created_date),
    },
  ];

  return (
    <div>
      {!isHomePage && (
        <>
          <AppBarCommon name="Devices" registerDevice={true} />
        </>
      )}

      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          style={{ marginTop: "20px" }}
          title="Devices"
          icons={tableIcons}
          columns={columns}
          options={{
            debounceInterval: 700,
            padding: "dense",
            filtering: filter,
            pageSize: 10,
          }}
          actions={[
            {
              icon: () => <FilterAltIcon />,
              onClick: () => handleChange(),
              tooltip: "Hide/Show Filter",
              isFreeAction: true,
            },
          ]}
          data={(query) =>
            new Promise((resolve, _reject) => {
              url += "/devices/v1?";

              //   pagination;
              url += `page=${query.page + 1}`;
              url += `&limit=${query.pageSize}`;

              //search
              if (query.search) {
                url += `&search=${query.search}`;
              }

              //sorting
              if (query.orderBy) {
                url += `&sort_by=${query.orderBy.field}&order=${query.orderDirection}`;
              }

              //filtering
              if (query.filters.length) {
                const filter = query.filters.map((filter) => {
                  return `&${filter.column.field}${filter.operator}${filter.value}`;
                });
                url += filter.join("");
              }
              getDevices(url).then((data) => {
                resolve({
                  data: data.devices,
                  page: query.page,
                  totalCount: data.page_metadata.total_count,
                });
              });
            })
          }
          components={{
            Pagination: (data) => (
              <>
                {isHomePage ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "40px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        window.location.pathname = hasAdminRole
                          ? "/admin/add-events"
                          : "/member/events";
                      }}
                      style={{ fontWeight: 600, fontSize: "16px" }}
                    >
                      View More
                    </Button>
                  </div>
                ) : (
                  <TablePagination {...data} />
                )}
              </>
            ),
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default DeviceTable;
